/* eslint-disable */
import React from 'react'
import Helmet from '../../../components/Helmet'

import { graphql } from 'gatsby'

import CoupleHikingBridge from '../../../images/heroes/couple-hiking-bridge.jpg'
import CoupleHikingBridgeMobile from '../../../images/heroes/couple-hiking-bridge-mobile.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../../images/brand-logos/aetna.svg'
import cigna from '../../../images/brand-logos/cigna.svg'
import regence from '../../../images/brand-logos/regence.svg'
import united from '../../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../../images/brand-logos/wellcare.svg'
import mutual from '../../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  AvailabilityForm,
  Billboard,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../../components/Header'
import footer from '../../../components/Footer'
import isCallCenterOpen from '../../../../../../utils/isOpen'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-dsnplp']

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title: 'Medicare Supplement (Medigap) Plan G | HealthcarePlans.com',
          description:
            'Medicare Supplement Plan G covers all of the gaps in Original Medicare except for the annual Part B deductible. Consult with our experts about Medigap Plan G.',
          canonical:
            'https://www.healthcareplans.com/plans/supplemental-medicare/plan-g',
          robots: 'follow,index',
        },
        path: '/plans/supplemental-medicare/plan-g',
        promoCode: '170328',
        customBreadCrumbs: [
          {
            shortName: 'Home',
            path: '/',
          },
          {
            shortName: 'Plans',
            path: '/plans',
          },
          {
            shortName: 'Supplemental Medicare',
            path: '/plans/supplemental-medicare',
          },
        ],
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <div className="hero-content">
          <div className="wrapper">
            <Billboard
              variant="full"
              textColor="Dark"
              image={
                <img
                  src={CoupleHikingBridge}
                  alt="an elderly couple hikes across a bridge and smiles"
                />
              }
              mobileImage={
                <img
                  src={CoupleHikingBridgeMobile}
                  alt="an elderly couple hikes across a bridge and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Explore Medicare Supplemental Insurance (Medigap) Plan G
                  </Typography>

                  <Typography variant="body">
                    Medigap Plan G is a Medicare supplemental insurance plan
                    designed to eliminate almost all out-of-pocket costs
                    associated with Original Medicare (Medicare Parts A & B). 
                  </Typography>
                  <Typography variant="body">
                    Read on to learn more about Medigap Plan G or call to speak
                    to an agent who can help you find plans in your area.
                  </Typography>

                  {isCallCenterOpen() ? (
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="primary"
                      className="margin-x-auto-small"
                      requestId={requestId}
                    >
                      Call {rotatedNumber} for Medigap Plan G information
                    </LinkButton>
                  ) : (
                    <AvailabilityForm
                      placeholderText="Zip Code"
                      buttonText="Find a Medicare D-SNP Plan"
                      buttonColor="primary"
                      butttonBackgroundColor="black"
                      className="avail-form"
                    />
                  )}
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos bg-lightgray"
          mainContent={
            <Typography variant="h2">
              We partner with the top insurance providers{' '}
            </Typography>
          }
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <VariableContent
          alignMainContent="left"
          mainContent={
            <>
              <Typography variant="h2">
                Medicare Supplement Plan G benefits and coverage
              </Typography>
              <Typography variant="body">
                Medigap Plan G, sometimes referred to as Medicare Supplement
                Plan G, is a type of{' '}
                <a href="/plans/supplemental-medicare/" className="inline">
                  Medicare supplemental insurance
                </a>{' '}
                available to those enrolled in Original Medicare (Medicare Parts
                A & B).{' '}
                <a
                  href="https://www.medicare.gov/health-drug-plans/medigap/basics/compare-plan-benefits"
                  target="_blank"
                  className="inline"
                >
                  Like Medigap Plan F (which is only available to those who
                  enrolled in Medicare before 2020)
                </a>
                , Medigap Plan G offers nearly comprehensive coverage for
                out-of-pocket expenses associated with Medicare.
              </Typography>
              <Typography variant="body" className="margin-top-0">
                In fact, the only out-of-pocket cost Plan G does not cover is
                your Medicare Part B deductible. View the list and table below
                to see in detail what is and is not covered when you enroll in
                Medicare Supplement Plan G.
              </Typography>
              <Typography variant="h4">
                What Plan G Covers from Medicare Part A (Hospital Services)
              </Typography>
              <Typography variant="body">
                <strong>Hospitalization:</strong> semi-private room and board,
                general nursing, services, and supplies
                <List>
                  <ListItem className="padding-left margin-bottom-0">
                    With Plan G, your Medicare Part A deductible is covered, so
                    you pay $0 out of pocket for:
                    <List>
                      <ListItem className="margin-bottom-0">
                        your first 60 days in the hospital,
                      </ListItem>
                      <ListItem className="margin-bottom-0">
                        your 61st-90th days in the hospital,
                      </ListItem>
                      <ListItem className="margin-bottom-0">
                        60 lifetime reserve days in the hospital,
                      </ListItem>
                      <ListItem className="margin-bottom-0">
                        an additional 365 days in the hospital, and
                      </ListItem>
                      <ListItem className="margin-bottom-0">
                        beyond the additional 365 days in the hospital.
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="body">
                <strong>Skilled nursing facility care:</strong> requires
                entering a Medicare-approved facility within 30 days of
                discharge from a hospital stay of no less than 3 days
                <List>
                  <ListItem className="padding-left margin-bottom-0">
                    With Plan G, your Medicare Part A deductible is covered, so
                    you pay $0 out-of-pocket for:
                    <List>
                      <ListItem className="margin-bottom-0">
                        your first 20 days in the facility,
                      </ListItem>
                      <ListItem className="margin-bottom-0">
                        days 21-100, and
                      </ListItem>
                      <ListItem className="margin-bottom-0">
                        days 101 and beyond.
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="body">
                <strong>Hospice care:</strong> includes pain relief, symptom
                management, and support for the terminally ill
                <List>
                  <ListItem className="padding-left margin-bottom-0">
                    With Plan G, you pay $0 out of pocket for hospice services
                    with a doctor’s certification of terminal illness.
                  </ListItem>
                </List>{' '}
              </Typography>
              <Typography variant="body">
                <strong>Blood</strong>
                <br />
                <List>
                  <ListItem className="padding-left">
                    With Plan G, you pay $0 out of pocket for your first three
                    pints of blood per calendar year.
                  </ListItem>
                </List>{' '}
              </Typography>
              <Typography variant="h4">
                What Plan G Covers from Medicare Part B (Medical Services)
              </Typography>
              <Typography variant="body">
                <strong>Home health care Medicare-approved services</strong>
                <List>
                  <ListItem className="padding-left margin-bottom-0">
                    With Plan G, you pay $0 out of pocket for medically
                    necessary skilled care services and related supplies.
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="body">
                <strong>Durable medical equipment</strong>
                <List>
                  <ListItem className="padding-left margin-bottom-0">
                    With Plan G, you pay only the $240 Medicare Part B
                    deductible; anything after that is covered.
                  </ListItem>
                </List>
              </Typography>
              <Typography variant="h4">
                Additional Benefits Covered by Plan G
              </Typography>
              <Typography variant="body">
                <strong>Foreign travel:</strong> necessary emergency care
                services during your first 60 days on a trip outside of the U.S.
                <List>
                  <ListItem className="padding-left margin-bottom-0">
                    With Plan G, you pay the first $250 for care, and then $0
                    out of pocket up to a $50,000 lifetime maximum.
                  </ListItem>
                </List>
              </Typography>
            </>
          }
          style={{
            color: '#1b0b54',
          }}
        ></VariableContent>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">
                How Plan G compares to other Medicare Supplement options
              </Typography>
            </>
          }
          alignMainContent="center"
          style={{
            color: '#1b0b54',
          }}
        >
          <div className="comparison-table bg-white medigap">
            <table className="table-main">
              <thead>
                <td>
                  <Typography variant="h6">Benefit Coverage</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan A</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan B</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan C</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan F</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan G</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan K</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan L</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan N</Typography>
                </td>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="h6">Medicare Part A</Typography>
                    <Typography vairant="body">
                      coinsurance/copayment
                    </Typography>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Partial</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">Medicare Part B</Typography>
                    <Typography vairant="body">
                      coinsurance/copayment
                    </Typography>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Blood (first three pints)
                    </Typography>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">Hospice</Typography>
                    <Typography variant="body">
                      coinsurance/copayment
                    </Typography>
                  </td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Skilled nursing facility coinsurance
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Medicare Part A deductible
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Medicare Part B deductible
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Medicare Part B excess charges
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Foreign travel emergency coverage
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">Out-of-pocket limit</Typography>
                  </td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>$7,060</td>
                  <td>$3,530</td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </table>
          </div>
        </VariableContent>

        <a
          href="https://www.trustpilot.com/review/clearlinkinsurance.com"
          target="_blank"
        >
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <span className="show-on-desktop">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-desktop.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
                <span className="show-on-mobile">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-mobile.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
              </>
            }
          />
        </a>

        <VariableContent
          backgroundColor="primary"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">Give us a call</Typography>
              <Typography variant="body">
                Our licensed agents are available Monday through Friday from
                6:00 am to 5:00 pm MT.
              </Typography>
              <LinkButton
                variant="feature"
                to={`tel:${rotatedNumber}`}
                color="white"
                className=""
                requestId={requestId}
              >
                <span className="text-primary">
                  Call {rotatedNumber} <br />
                  TTY 711
                </span>
              </LinkButton>
            </>
          }
        ></VariableContent>

        <VariableContent
          alignMainContent="left"
          mainContent={
            <Typography variant="h2">
              Frequently asked questions about Medigap Plan G
            </Typography>
          }
          className="faq-section-padding"
          style={{ color: '#1B0B54' }}
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  title: 'Who is eligible for Medigap Plan G?',
                  content: (
                    <>
                      <Typography>
                        To be eligible for Medicare supplemental insurance
                        plans, you must be enrolled in Original Medicare (Parts
                        A & B) through the federal government. Your enrollment
                        period for Medigap coverage, including enrolling in
                        Medigap Plan G, lasts for six months after enrolling in
                        Original Medicare for the first time.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'How do I enroll in Medigap Plan G?',
                  content: (
                    <>
                      <Typography>
                        During your Medicare supplement enrollment period, which
                        lasts for six months after enrolling in Original
                        Medicare for the first time, call{' '}
                        <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>{' '}
                        Monday through Friday between 6:00 am and 5:00 pm MT to
                        speak to an expert, licensed, U.S.-based agent.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'What is the Medigap Plan G deductible in 2024?',
                  content: (
                    <Typography>
                      Medicare supplemental insurance Plan G covers all
                      out-of-pocket costs associated with Medicare Parts A & B
                      except for the Medicare Part B deductible, which is $240
                      for 2024.
                    </Typography>
                  ),
                },
                {
                  title: 'Does Medicare Plan G cover dental?',
                  content: (
                    <Typography>
                      Medigap Plan G does not provide dental coverage. If you
                      are interested in a plan that does feature dental
                      coverage, we recommend checking out our{' '}
                      <a href="/plans/medicare-advantage">
                        Medicare Advantage (Medicare Part C) plans
                      </a>
                      . You can also work with one of our agents to find an
                      ancillary dental coverage plan to supplement your existing
                      Medicare coverage.
                    </Typography>
                  ),
                },
                {
                  title: 'Does Medicare Plan G cover prescriptions?',
                  content: (
                    <>
                      <Typography>
                        Medigap Plan G is associated with out-of-pocket costs
                        for Original Medicare (Parts A & B) and provides no
                        prescription drug coverage. If you are interested in
                        prescription drug plans (PDPs), take a look at the{' '}
                        <a href="/plans/medicare-part-d/">
                          D plans available from our partners
                        </a>
                        .
                      </Typography>
                    </>
                  ),
                },
                {
                  title:
                    'Are my Medigap options limited based on the state I live in?',
                  content: (
                    <>
                      <Typography>
                        Medigap plans are standardized across most states. If
                        you live in Massachusetts, Minnesota, or Wisconsin
                        policies are not standardized in the same way, which may
                        limit your plan availability. To be sure, give us a call
                        at <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>{' '}
                        Monday through Friday between 6:00 am and 5:00 pm MT to
                        speak to an expert, licensed, U.S.-based agent.
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Medicare resources</Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../../../images/people/couple-hugging.jpg"
                  alt="cute elderly couple hugging"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/plans/supplemental-medicare/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Supplemental Insurance Plan
                  </a>
                </Typography>
                <Typography variant="body">
                  Learn about available Medigap plans designed to fill gaps in
                  Original Medicare coverage.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/people/grandparents-on-couch-with-grandkids.jpg"
                  alt="elederly couple sit with grandkids on couch and smile"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/when-is-medicare-advantage-annual-enrollment/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Annual Enrollment Period 101
                  </a>
                </Typography>
                <Typography variant="body">
                  When is the Medicare Annual Enrollment Period? Get answers to
                  this and other AEP-related questions.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../../images/people/biking-on-beach.jpg"
                  alt="an elderly couple rides bicycles on the beach"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/medicare-enrollment-101/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Enrollment Overview
                  </a>
                </Typography>
                <Typography variant="body">
                  Get fast facts about Medicare Enrollment, including when you
                  qualify and when to enroll.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
